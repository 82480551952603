/* You can add global styles to this file, and also import other style files */
@import "./assets/scss/app.scss";
@import url('https://code.ionicframework.com/ionicons/2.0.1/css/ionicons.min.css');
@import url('https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.3.0/css/flag-icon.min.css');
@import 'ngx-toastr/toastr';
@import '@angular/material/prebuilt-themes/deeppurple-amber.css';
@import "~@ng-select/ng-select/themes/default.theme.css";


.router-wrapper {
    position: relative;
    overflow: hidden;
    width: 100vw;
    height: calc(100% - 47px);
    perspective: 1200px;
    transform-style: preserve-3d;
}

.perm .accordion-body {
    padding-left: 0px;

}

:host {
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    background: #60d7a9;
}

.jqvmap-zoomin,
.jqvmap-zoomout {
    width: 20px !important;
    height: 20px !important;
}

mat-icon {
    transform: translate(50%, -50%);
    position: absolute;
    top: 50%;
    right: 0;
    cursor: pointer;
    color: rgba(0, 0, 0, 0.54);
}

.mat-mdc-text-field-wrapper {
    width: 250px !important;
    height: 40px !important;
    background-color: white !important;
}

.mat-date-range-input-container {
    font-size: 12px !important;
}

.tab-btn-act {
    margin-bottom: 1.5rem !important;
}

.size-list-selector {
    display: flex;

    span {
        margin-top: 3%;

        &:first-of-type {
            margin-right: 15px;
        }

        &:last-of-type {
            margin-left: 15px;
        }
    }
}

.mr-btn {
    margin-right: 10px;
}

.act-btn {
    font-size: x-large !important;
    color: #7366ff !important;
    cursor: pointer;
}

.act-btn-delete {
    cursor: pointer;
}

.place-holder {
    text-shadow: 0 0 black !important;
}

.mat-mdc-text-field-wrapper {
    background-color: #f5f5f5;
}

.dark-only .mat-mdc-text-field-wrapper {
    background-color: #333333;
}

.mat-mdc-form-field {
    flex-direction: column-reverse !important;
}

.btn-mr {
    margin-right: 10px;
}

label {
    span.required::before {
        content: '*';
        color: red;
        margin-right: 4px;
    }
}

.dragdrop-list {
    width: 250% !important;
}

th input {
    font-size: small !important;
}

th select {
    font-size: small !important;
}

.dark-only .ng-select-container {
    background-color: black !important;
}

.dark-only .mat-mdc-form-field-focus-overlay {
    opacity: 1 !important;
}

.dark-only .cdk-drop-list.dragdrop-list {
    background-color: black !important;
}

.dark-only .dragdrop-block {
    background-color: black !important;
    color: cornsilk !important;
}

.dark-only .typo-data {
    background-color: black !important;
}

.dark-only .typo-data th {
    color: thistle !important;
}

.dark-only .typo-data td {
    color: thistle !important;
}

.dark-only .typo-data .pre-search-table {
    color: thistle !important;
}

.form-footer-read {
    margin-bottom: 1.5rem !important;
}

span {
    text-transform: unset !important;
}

@for $i from 1 through 6 {
    h#{$i} {
        text-transform: unset !important;
    }
}

li {
    text-transform: unset !important;

    a {
        text-transform: unset !important;
    }
}


.range-filter-date {
    .mat-mdc-form-field-subscript-wrapper {
        display: none !important;
    }

    .mat-mdc-text-field-wrapper {
        width: 85% !important;
        height: 40px !important;
        background-color: white !important;
        padding-left: 0 !important;
    }

    .mat-date-range-input-wrapper {
        position: relative !important;
        padding: unset !important;
        background-color: unset !important;
    }

    .mat-mdc-form-field {
        margin-right: -15px !important;
    }
}

@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
    .range-filter-date {
        .mat-mdc-form-field-subscript-wrapper {
            display: none !important;
        }

        .mat-mdc-text-field-wrapper {
            width: 55% !important;
        }
    }
}

.dark-only .range-filter-date {
    .mat-mdc-text-field-wrapper {
        background-color: #333333;
    }
}

.center-val {
    text-align: center;
}


.textile-page {
    font-family: 'Montserrat', sans-serif;
}

.bg-beige {
    background-color: #D9D0C6;
}

.card-transition {
    transition: all .2s ease-in-out;
}

.card-transition:hover,
.card-transition:focus {
    -webkit-transform: translateY(-0.1875rem);
    transform: translateY(-0.1875rem);
}

.gotop {
    background-color: rgba(248, 249, 250, 0.5);
    z-index: 999;
    right: 1rem;
    bottom: 1rem;
    transition: background-color 0.5s ease-in-out;
}

.gotop:hover {
    background-color: rgba(248, 249, 250, 1);
}

iframe.gmaps {
    display: block;
    margin: 0 auto;
    max-width: 1000px;
}

.indiceRep {
    display: inline-block;
    width: 15px;
    height: 15px;
    border: 1px solid rgb(108, 117, 125);
}

.suiviKm img {
    width: 2.5rem;
    height: 2.5rem;
}

.scroll-horizontal {
    white-space: nowrap;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
}

.scroll-horizontal p,
.scroll-horizontal h3 {
    white-space: initial;
}

.x-small {
    font-size: .5em;
}

h3 {
    width: 100%;
}

.table-responsive {
    overflow-x: unset !important;
}

.aggregated-edit {
    width: -webkit-fill-available !important;
}

.dark-only .domain-name {
    background: #374558 !important;
}

.ng-select-container {
    height: 45px;

    .ng-placeholder {
        margin-top: 6px;
    }
}

.table {
    th {
        color: #fff;
    }

    .form-select,
    .form-date {
        color: #bababa;
    }

    .mat-mdc-form-field {
        display: unset;
    }

    .mat-mdc-form-field-icon-suffix {
        padding: 1px 0px 6px 7px;
    }
}

input[type="file"]::-webkit-file-upload-button {
    color: #2F2F3B;
    background-color: #A9A9A9;
}

#chart-alert.apexcharts-canvas {
    padding-top: 60px;
}

.dig-stat .apexcharts-canvas {
    margin-left: -44px !important;
}

.dark-only .hm-dm label,
.dark-only mat-icon,
.dark-only .mat-datepicker-toggle{
    color:#fff9;
}