/* You can add global styles to this file, and also import other style files */
@import "./assets/scss/app.scss";
@import url('https://code.ionicframework.com/ionicons/2.0.1/css/ionicons.min.css');
@import url('https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.3.0/css/flag-icon.min.css');
@import 'ngx-toastr/toastr';
@import '@angular/material/prebuilt-themes/deeppurple-amber.css';
@import "~@ng-select/ng-select/themes/default.theme.css";

.router-wrapper {
	position: relative;
	overflow: hidden;
	width: 100vw;
	height: calc(100% - 47px);
	perspective: 1200px;
	transform-style: preserve-3d;
}

.tooltip-management .tooltip-inner {
	max-width: 200px;
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: normal;
  }

.perm .accordion-body {
	padding-left: 0px;
}

:host {
	display: block;
	position: absolute;
	width: 100%;
	height: 100%;
	background: #60d7a9;
}

.jqvmap-zoomin,
.jqvmap-zoomout {
	width: 20px !important;
	height: 20px !important;
}

.mat-mdc-text-field-wrapper {
	background-color: white !important;
}

.mat-date-range-input-container {
	font-size: 12px !important;
}

.tab-btn-act {
	margin-bottom: 0.75rem !important;
	padding-top: 0.75rem;
}

.size-list-selector {
	display: flex;

	span {
		margin-top: 3%;

		&:first-of-type {
			margin-right: 15px;
		}

		&:last-of-type {
			margin-left: 15px;
		}
	}
}

.mr-btn {
	margin-right: 10px;
}

.act-btn {
	color: #7366ff !important;
	cursor: pointer;
}

.act-btn-delete {
	cursor: pointer;
}

.place-holder {
	text-shadow: 0 0 black !important;
}

.mat-mdc-text-field-wrapper {
	background-color: #f5f5f5;
}

.dark-only .mat-mdc-text-field-wrapper {
	background-color: #333333;
}

.mat-mdc-form-field {
	flex-direction: column-reverse !important;
}

.btn-mr {
	margin-right: 10px;
}

label {
	span.required::before {
		content: '*';
		color: red;
		margin-right: 4px;
	}
}

.dragdrop-list {
	width: 250% !important;
}

th input {
	font-size: small !important;
}

th select {
	font-size: small !important;
}

.dark-only .ng-select-container {
	background-color: black !important;
}

.dark-only .mat-mdc-form-field-focus-overlay {
	opacity: 1 !important;
}

.dark-only .cdk-drop-list.dragdrop-list {
	background-color: black !important;
}

.dark-only .dragdrop-block {
	background-color: black !important;
	color: cornsilk !important;
}

.dark-only .typo-data {
	background-color: black !important;
}

.dark-only .typo-data th {
	color: thistle !important;
}

.dark-only .typo-data td {
	color: thistle !important;
}

.dark-only .typo-data .pre-search-table {
	color: thistle !important;
}

.form-footer-read {
	margin-bottom: 1.5rem !important;
}

span {
	text-transform: unset !important;
}

@for $i from 1 through 6 {
	h#{$i} {
		text-transform: unset !important;
	}
}

li {
	text-transform: unset !important;

	a {
		text-transform: unset !important;
	}
}

@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
	.range-filter-date {
		.mat-mdc-text-field-wrapper {
			width: 55% !important;
		}
	}
}

.center-val {
	text-align: center;
}

.textile-page {
	font-family: 'Montserrat', sans-serif;
}

.bg-beige {
	background-color: #D9D0C6;
}

.card-transition {
	transition: all .2s ease-in-out;
}

.card-transition:hover,
.card-transition:focus {
	-webkit-transform: translateY(-0.1875rem);
	transform: translateY(-0.1875rem);
}

.gotop {
	background-color: rgba(248, 249, 250, 0.5);
	z-index: 999;
	right: 1rem;
	bottom: 1rem;
	transition: background-color 0.5s ease-in-out;
}

.gotop:hover {
	background-color: rgba(248, 249, 250, 1);
}

iframe.gmaps {
	display: block;
	margin: 0 auto;
	max-width: 1000px;
}

.indiceRep {
	display: inline-block;
	width: 15px;
	height: 15px;
	border: 1px solid rgb(108, 117, 125);
}

.suiviKm img {
	width: 2.5rem;
	height: 2.5rem;
}

.scroll-horizontal {
	white-space: nowrap;
	overflow-x: auto;
	-webkit-overflow-scrolling: touch;
	-ms-overflow-style: -ms-autohiding-scrollbar;
}

.scroll-horizontal p,
.scroll-horizontal h3 {
	white-space: initial;
}

.x-small {
	font-size: .5em;
}

h3 {
	width: 100%;
}

.table-responsive {
	overflow-x: unset !important;
}

.aggregated-edit {
	width: -webkit-fill-available !important;
}

.dark-only .domain-name {
	background: #374558 !important;
}

.ng-select-container {
	height: 45px;

	.ng-placeholder {
		margin-top: 6px;
	}
}

.table {
	th {
		color: #fff;
	}

	.form-select,
	.form-date {
		color: #bababa;
	}

	.mat-mdc-form-field {
		display: unset;
	}

	.mat-mdc-form-field-icon-suffix {
		padding: 1px 0px 6px 7px;
	}
}

input[type="file"]::-webkit-file-upload-button {
	color: #2F2F3B;
	background-color: #A9A9A9;
}

#chart-alert.apexcharts-canvas {
	padding-top: 60px;
}

.dig-stat .apexcharts-canvas {
	margin-left: -44px !important;
}

.toggle-btn {
	margin-left: -1.5rem;
}

.custom-datatable {
	margin-right: 0.75rem;
}

.mt-tree {
	margin-top: 0.75rem;
}

#ctg-tree .mat-drawer {
	bottom: unset !important;
}

.mat-drawer-content {
	width: 100%;
}

.dark-only {

	#mat-con-media,
	#mat-product,
	#mat-tree-view {
		background-color: hsl(225, 14%, 17%) !important;
	}

	#tree>div,
	#tree .tree-node-label {
		color: #fff9;
	}

	#tree .tree-node-label:hover {
		background-color: #333333;
	}

	.active-node-parent {
		background-color: #123477 !important;
		border-radius: 4px;
		transition: background-color 0.3s ease-in-out;
	}

	.cm-list,
	.product-list {
		background-color: hsl(225, 14%, 17%) !important;
		color: rgba(255, 255, 255, 0.6);

		td {
			color: #fff9;
		}

		.table thead.bg-primary tr th {
			color: #fff9;
		}
	}
}

.active-node-parent {
	background-color: #f5f6f9 !important;
	border-radius: 4px;
	transition: background-color 0.3s ease-in-out;

	:hover {
		background-color: #f5f6f9;
		color: #7366ff;
	}
}

.dark-only #tree-modal-container,
.dark-only #change-modal-container {
	.mat-mdc-form-field-focus-overlay {
		background-color: white;
	}

	background-color: #252831;
}

.dark-only {

	.domain-ng-select .ng-select-container,
	#product-sitemap.mat-mdc-button,
	.mat-tree-node>span.node-button>i,
	#connected-media-sitemap.mat-mdc-button {
		color: rgba(255, 255, 255, 0.6);
	}

	.mat-tree-node:hover,
	.tree-node-label.active-node,
	#tree>div:hover {
		background-color: #333333 !important;
	}

	#tree-modal-container h4,
	#change-modal-container h4 {
		color: #fff9;
	}
}

#tree.add-root {
	margin-top: 2px;
}

.mat-mdc-menu-content {
	padding: unset !important;

	.mdc-list-item__primary-text {
		font-size: small !important;
	}
}

.dark-only .mat-mdc-menu-content {
	background-color: #252831 !important;

	.mdc-list-item__primary-text {
		color: #fff9 !important;
	}
}

#mat-mdc-dialog-0>div>div {
	overflow-y: unset !important;
}
